var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-main",
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _c("span", [_vm._v("Add content here.")]),
                _c("span", [_vm._v("User is logged in: ")]),
                !_vm.user
                  ? _c("span", [_vm._v("Not logged in.")])
                  : !_vm.user.mailAddress
                  ? _c("span", [_vm._v("No mailaddress.")])
                  : _c("span", [_vm._v(_vm._s(_vm.user.mailAddress))]),
                _c("p", [
                  _vm._v(
                    " Has user permission to create Users: " +
                      _vm._s(_vm.hasPermissionCreateUsers) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " Has user permission to update Users: " +
                      _vm._s(_vm.hasPermissionUpdateUsers) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " Has user permission to delete Users: " +
                      _vm._s(_vm.hasPermissionDeleteUsers) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " Has user permission to read Users: " +
                      _vm._s(_vm.hasPermissionReadUsers) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " Has user feature to search DPMA: " +
                      _vm._s(_vm.hasFeatureDE) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " Has user feature to display Owner: " +
                      _vm._s(_vm.hasFeatureDisplayOwner) +
                      " "
                  ),
                ]),
              ]),
              _c(
                "v-col",
                { staticClass: "d-flex flex-row" },
                [
                  _c("radial-bar", {
                    attrs: {
                      width: 130,
                      height: 130,
                      label: "Risk",
                      value: 10,
                      showValue: true,
                      startAngle: -135,
                      endAngle: 135,
                      valueColors: _vm.riskColors,
                    },
                  }),
                  _c("radial-bar", {
                    attrs: {
                      width: 130,
                      height: 130,
                      label: "Risk",
                      value: 20,
                      showValue: true,
                      startAngle: -135,
                      endAngle: 135,
                      valueColors: _vm.riskColors,
                    },
                  }),
                  _c("radial-bar", {
                    attrs: {
                      width: 130,
                      height: 130,
                      label: "Risk",
                      value: 30,
                      showValue: true,
                      startAngle: -135,
                      endAngle: 135,
                      valueColors: _vm.riskColors,
                    },
                  }),
                  _c("radial-bar", {
                    attrs: {
                      width: 130,
                      height: 130,
                      label: "Risk",
                      value: 40,
                      showValue: true,
                      startAngle: -135,
                      endAngle: 135,
                      valueColors: _vm.riskColors,
                    },
                  }),
                  _c("radial-bar", {
                    attrs: {
                      width: 130,
                      height: 130,
                      label: "Risk",
                      value: 50,
                      showValue: true,
                      startAngle: -135,
                      endAngle: 135,
                      valueColors: _vm.riskColors,
                    },
                  }),
                  _c("radial-bar", {
                    attrs: {
                      width: 130,
                      height: 130,
                      label: "Risk",
                      value: 60,
                      showValue: true,
                      startAngle: -135,
                      endAngle: 135,
                      valueColors: _vm.riskColors,
                    },
                  }),
                  _c("radial-bar", {
                    attrs: {
                      width: 130,
                      height: 130,
                      label: "Risk",
                      value: 70,
                      showValue: true,
                      startAngle: -135,
                      endAngle: 135,
                      valueColors: _vm.riskColors,
                    },
                  }),
                  _c("radial-bar", {
                    attrs: {
                      width: 130,
                      height: 130,
                      label: "Risk",
                      value: 80,
                      showValue: true,
                      startAngle: -135,
                      endAngle: 135,
                      valueColors: _vm.riskColors,
                    },
                  }),
                  _c("radial-bar", {
                    attrs: {
                      width: 130,
                      height: 130,
                      label: "Risk",
                      value: 90,
                      showValue: true,
                      startAngle: -135,
                      endAngle: 135,
                      valueColors: _vm.riskColors,
                    },
                  }),
                  _c("radial-bar", {
                    attrs: {
                      width: 130,
                      height: 130,
                      label: "Risk",
                      value: 100,
                      showValue: true,
                      startAngle: -135,
                      endAngle: 135,
                      valueColors: _vm.riskColors,
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c(
                    "v-btn",
                    {
                      on: {
                        click: function () {
                          return _vm.$emitter.emit("GETFEEDBACK")
                        },
                      },
                    },
                    [_vm._v("Show Feedback Common")]
                  ),
                  _c(
                    "v-btn",
                    {
                      on: {
                        click: function () {
                          return _vm.$emitter.emit("GETFEEDBACK", "Create")
                        },
                      },
                    },
                    [_vm._v("Show Feedback Create")]
                  ),
                  _c(
                    "v-btn",
                    {
                      on: {
                        click: function () {
                          return _vm.$emitter.emit("GETFEEDBACK", null, 10)
                        },
                      },
                    },
                    [_vm._v("Show Feedback Delayed")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }