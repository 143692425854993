var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        _vm._l(_vm.brands, function (brand, index) {
          return _c(
            "v-col",
            { key: _vm.getIdOrIndex(brand, index), attrs: { cols: "12" } },
            [
              _c("HorizontalBrandTile", {
                attrs: { brand: brand, canAddToBookmarks: true },
              }),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }