
























import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';
import { Brand, BrandIndexItem, BrandQueryResultItem } from '@/api/braendz';

import HorizontalBrandTile from './HorizontalBrandTile.vue';

@Component({
    components: {
        HorizontalBrandTile
    }
})
export default class HorizontalBrandTileGrid extends Vue {
    public isBrandQueryResultItem = (b: BrandQueryResultItem | BrandIndexItem | Brand): b is BrandQueryResultItem => {
        return (b as BrandQueryResultItem)?.indexItem !== undefined;
    }
    public isBrandIndexItem = (b: BrandQueryResultItem | BrandIndexItem | Brand): b is BrandIndexItem => {
        return (b as BrandIndexItem)?.brandId !== undefined;
    }
    public isBrand = (b: BrandQueryResultItem | BrandIndexItem | Brand): b is Brand => {
        return !this.isBrandQueryResultItem(b) && !this.isBrandIndexItem(b);
    }

    // Component Properties:
    @Prop({ required: true })
    public brands!: (BrandQueryResultItem | BrandIndexItem | Brand)[];

    // Methods:
    public getIdOrIndex(item: BrandQueryResultItem | BrandIndexItem | Brand | null | undefined, index: number) {
        if(item) {
            if(this.isBrandQueryResultItem(item)) return item.indexItem?.id ?? item.indexItem?.brandId ?? index;
            if(this.isBrandIndexItem(item)) return item.id ?? item.brandId ?? index;
            if(this.isBrand(item)) return item.id ?? index;
        }
        return index;
    }
}
