



































































import { AccountsService, User } from '@/api/braendz';
import { PermissionLevel } from '@/models/PermissionLevel';
import { PermissionResourceType } from '@/models/PermissionResourceType';
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';
import VerticalBrandTileGrid from '@/components/Brands/VerticalBrandTileGrid.vue'
import HorizontalBrandTileGrid from '@/components/Brands/HorizontalBrandTileGrid.vue'
import { dummyBrand } from '@/models/DummyBrand';
import RadialBar from '@/components/Statistics/RadialBar.vue';

@Component({
  components: {
    VerticalBrandTileGrid,
    HorizontalBrandTileGrid,
    RadialBar
  }
})
export default class Test extends Vue {

  public get dummyBrand() {
    return dummyBrand;
  }

  public get user(): User | null {
    return this.$store.state.user.object as User | null;
  }

  public get hasPermissionUpdateUsers(): boolean {
    return this.$store.getters.hasPermission(PermissionResourceType.Users, PermissionLevel.Update);
  }
  public get hasPermissionCreateUsers(): boolean {
    return this.$store.getters.hasPermission(PermissionResourceType.Users, PermissionLevel.Create);
  }
  public get hasPermissionDeleteUsers(): boolean {
    return this.$store.getters.hasPermission(PermissionResourceType.Users, PermissionLevel.Delete);
  }
  public get hasPermissionReadUsers(): boolean {
    return this.$store.getters.hasPermission(PermissionResourceType.Users, PermissionLevel.Read);
  }

  public get hasFeatureDE(): boolean {
    return this.$store.getters.hasFeature('CanSearchDE');
  }

  public get hasFeatureDisplayOwner(): boolean {
    return this.$store.getters.hasFeature('CanDisplayOwner');
  }

  public riskColors = [
    { color: '#269926', limit: 9.09 },
    { color: '#59AB20', limit: 18.18 },
    { color: '#8BBF1B', limit: 27.27 },
    { color: '#BDD317', limit: 36.36 },
    { color: '#E0E611', limit: 45.45 },
    { color: '#FFC107', limit: 54.54 },
    { color: '#F29E27', limit: 63.63 },
    { color: '#E67C37', limit: 72.72 },
    { color: '#D95940', limit: 81.81 },
    { color: '#CE3440', limit: 90.90 },
    { color: '#DB0000', limit: 100 }
];

}


